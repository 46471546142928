import React from "react";
import styled from "styled-components";
import { Paragraph } from "../../styles/StyledComponents/Paragraph";
import { PhotoGrid } from "../../styles/StyledComponents/PhotoGrid";
import { SectionLogo } from "../../styles/StyledComponents/SectionLogo";
import { SectionTitle } from "../../styles/StyledComponents/SectionTitle";
import { Wrapper } from "../../styles/StyledComponents/Wrapper";
import expoOne from "../../images/expo-one.jpg";
import expoTwo from "../../images/expo-two.jpg";
import bridge from "../../images/bridge.jpg";
import sunnyPath from "../../images/sunny-path.jpg";
import proteusLogo from "../../images/proteus-logo.png";
import { Photo } from "../../styles/StyledComponents/Photo";
import OpeningHours from "./../OpeningHours/OpeningHours";

const Exhibition = () => (
  <Section>
    <SectionTitle>Razstava</SectionTitle>
    <Paragraph>
      Posestvo leži na stičišču visokega in nizkega krasa, in trenutna razstava
      vključuje prikaz številnih posebnosti tega občutljivega območja. Žive
      kamere nudijo tudi vpogled v podzemni življenjski prostor in navade črnega
      močerila. Okoliška razgibana pokrajina, njen bogat živalski in rastlinski
      svet, sta izkušnja sama zase! Vsak letni čas nas obdaruje z intenzivnimi
      zvoki, svetlobo, barvami in vonji.
    </Paragraph>

    <SectionLogo>
      <img src={proteusLogo} alt="proteus" />
    </SectionLogo>
    <div className="link-container">
      <a href="https://www.crni-moceril.si/"> www.crni-moceril.si</a>
    </div>

    <OpeningHours />

    <PhotoGrid>
      <Photo>
        <img src={expoOne} alt="razstava" />
      </Photo>
      <Photo>
        <img src={expoTwo} alt="razstava-alt" />
      </Photo>
      <Photo>
        <img src={sunnyPath} alt="pot" />
      </Photo>
      <Photo>
        <img src={bridge} alt="most" />
      </Photo>
    </PhotoGrid>
  </Section>
);

export default Exhibition;

const Section = styled(Wrapper)`
  /* == Mobile ===  */

  ${SectionLogo} {
    margin: 2rem 0 0 0;
    width: 150px;
    height: auto;
  }

  .link-container {
    margin: 1rem 0 0 0;
    a {
      text-decoration: none;
      font-size: 24px;
      color: #877aa4;
      font-weight: bold;

      &:hover {
        color: #d66b6b;
      }
    }
  }

  /* == Small desktop ===  */
  @media (min-width: 768px) {
  }

  /* == Large desktop ===  */
  @media (min-width: 1200px) {
  }
`;
