import React from "react";
import styled from "styled-components";
import { Paragraph } from "../../styles/StyledComponents/Paragraph";
import { PhotoGrid } from "../../styles/StyledComponents/PhotoGrid";
import { SectionLogo } from "../../styles/StyledComponents/SectionLogo";
import { SectionTitle } from "../../styles/StyledComponents/SectionTitle";
import { Wrapper } from "../../styles/StyledComponents/Wrapper";
import expoOne from "../../images/expo-one.jpg";
import expoTwo from "../../images/expo-two.jpg";
import bridge from "../../images/bridge.jpg";
import sunnyPath from "../../images/sunny-path.jpg";
import proteusLogo from "../../images/proteus-logo.png";
import { Photo } from "../../styles/StyledComponents/Photo";
import OpeningHoursEn from "../OpeningHours/OpeningHoursEn";

const ExhibitionEn = () => (
  <Section>
    <SectionTitle>Exhibition</SectionTitle>
    <Paragraph>
      The property is located at a merging point of high and low karst. The
      current exhibition explores the fragility of this unique environment. You
      will be able to observe a live stream of the black olm in its natural
      habitat. We do not keep any of them in captivity, therefore it is only
      possible to observe them with cameras placed in their natural habitat.
      Furthermore, the rich flora and fauna is an experience of its own. Each
      season gifts us with a collection of intense sounds, lights, colours and
      smells.
    </Paragraph>

    <SectionLogo>
      <img src={proteusLogo} alt="proteus" />
    </SectionLogo>
    <div className="link-container">
      <a href="https://www.crni-moceril.si/"> www.crni-moceril.si</a>
    </div>

    <OpeningHoursEn />

    <PhotoGrid>
      <Photo>
        <img src={expoOne} alt="razstava" />
      </Photo>
      <Photo>
        <img src={expoTwo} alt="razstava-alt" />
      </Photo>
      <Photo>
        <img src={sunnyPath} alt="pot" />
      </Photo>
      <Photo>
        <img src={bridge} alt="most" />
      </Photo>
    </PhotoGrid>
  </Section>
);

export default ExhibitionEn;

const Section = styled(Wrapper)`
  /* == Mobile ===  */

  ${SectionLogo} {
    margin: 2rem 0 0 0;
    width: 150px;
    height: auto;
  }

  .link-container {
    margin: 1rem 0 0 0;
    a {
      text-decoration: none;
      font-size: 24px;
      color: #877aa4;
      font-weight: bold;

      &:hover {
        color: #d66b6b;
      }
    }
  }

  /* == Small desktop ===  */
  @media (min-width: 768px) {
  }

  /* == Large desktop ===  */
  @media (min-width: 1200px) {
  }
`;
