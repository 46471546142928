import styled from "styled-components";

export const Wrapper = styled.section`
  /* == Mobile ===  */
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 95vw;

  /* == Small desktop ===  */
  @media (min-width: 768px) {
    /* height: 60vh; */
  }

  /* == Large desktop ===  */
  @media (min-width: 1200px) {
    width: 75vw;
  }

  @media (min-width: 2000px) {
    width: 60vw;
  }
`;
