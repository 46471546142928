import React from "react";
import styled from "styled-components";
import { Wrapper } from "../../styles/StyledComponents/Wrapper";

const OpeningHours = () => (
  <Section>
    <div className="column">
      <h3 className="title">Delovni čas</h3>
      <h4>Od torka do nedelje</h4>
      <p>10:00 - 19:00</p>
      <h4>Zaprto ob ponedeljkih</h4>
    </div>
    <div className="column">
      <h3 className="title">Vodeni Ogledi</h3>
      <h4>Od torka do nedelje</h4>
      <p>10:00</p>
      <p>17:00</p>
      <p>18:00</p>
    </div>
    <div className="column">
      <h3 className="title">Vstopnina</h3>
      <h4>Razstava</h4>

      <div className="text-container">
        <p>Odrasli (18-65)</p> <p>3.00EUR</p>
      </div>
      <div className="text-container">
        <p>Otroci / upokojenci</p> <p>1.50EUR</p>
      </div>

      <h4>Vodeni ogledi</h4>
      <div className="text-container">
        <p>Odrasli (18-65)</p> <p>7.00EUR</p>
      </div>

      <div className="text-container">
        <p>Otroci / upokojenci</p> <p>3.00EUR</p>
      </div>
    </div>
  </Section>
);

export default OpeningHours;

const Section = styled(Wrapper)`
  /* == Mobile ===  */
  display: flex;
  margin: 1rem 0 1rem;
  padding: 1rem;
  border: 1px solid gray;
  border-radius: 4px;

  .column {
    width: 100%;
    height: 100%;
    margin: 1rem 0;

    .title {
      margin: 1rem 0;
    }

    h4,
    h3,
    p {
      display: flex;
      text-transform: uppercase;
      justify-content: center;
      align-items: center;
    }

    h3 {
    }

    h4 {
      padding: 0.75rem 0;
    }

    .text-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  /* == Small desktop ===  */
  @media (min-width: 768px) {
    .text-container {
      width: 50%;
      margin: 0 auto;
    }
  }

  /* == Large desktop ===  */
  @media (min-width: 1200px) {
    /* width: 60vw; */
    margin: 2rem 0 2rem;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    .text-container {
      width: 100%;
      /* margin: 0; */
    }

    .column {
      margin: 0;

      .title {
        margin: 0 0 1rem 0;
      }
    }
  }

  @media (min-width: 2000px) {
    width: 70%;
  }
`;
