import React from "react";
import styled from "styled-components";
import { Paragraph } from "../../styles/StyledComponents/Paragraph";
import { PhotoGrid } from "../../styles/StyledComponents/PhotoGrid";
import { SectionLogo } from "../../styles/StyledComponents/SectionLogo";
import { SectionTitle } from "../../styles/StyledComponents/SectionTitle";
import { Wrapper } from "../../styles/StyledComponents/Wrapper";
import foodFish from "../../images/food-fish.jpg";
import grannieDog from "../../images/grannie-dog.jpg";
import lamutLogo from "../../images/lamut-logo.png";
import { Photo } from "../../styles/StyledComponents/Photo";
import lamut from "../../images/lamut.jpg";
import sheep from "../../images/sheep.jpg";
// import menuPoletje from
import { Link } from "gatsby";

const Food = () => (
  <Section>
    <SectionTitle>Hrana</SectionTitle>
    <Paragraph>
      Nudimo domačo hrano, ki jo pridelamo na naši eko certificirani kmetiji. Ob
      domači raznovrstni zelenjavi in sadju daje izvir Jelševniščice čisto vodo
      in kisik našim postrvem, medtem ko travniški nasadi nudijo
      najkvalitetnejšo pašo našim ovcam. Za prave gurmane vzgajamo tudi nekaj
      sulcev.
    </Paragraph>
    <div className="link-container">
      <Link to="/menu" className="link">
        Oglejte si jedilni list
      </Link>
    </div>
    <PhotoGrid>
      <Photo>
        <img src={foodFish} alt="fish" />
      </Photo>
      <Photo>
        <img src={grannieDog} alt="dog" />
      </Photo>
    </PhotoGrid>

    <SectionLogo>
      <img src={lamutLogo} alt="lamut" />
    </SectionLogo>
    <Paragraph>
      V osrčju Bele krajine oživljamo travniške nasade jablan, zlasti starih
      sort, kot so kraljevič, bobovec, carjevič. Travniški nasad je edinstven
      življenski prostor, ki je s stoletno tradicijo jamstvo pristne pridelave
      jabolk. Lamut je znamka jabolčnih produktov in drugih pridelkov iz naših
      vrtov.
    </Paragraph>
    <PhotoGrid>
      <Photo>
        <img src={lamut} alt="lamut" />
      </Photo>
      <Photo>
        <img src={sheep} alt="sheep" />
      </Photo>
    </PhotoGrid>
  </Section>
);

export default Food;

const Section = styled(Wrapper)`
  /* == Mobile ===  */

  ${SectionLogo} {
    margin: 2rem 0 0 0;
    width: 150px;
    height: auto;
  }

  .link-container {
    margin: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .link {
      text-transform: uppercase;
      font-weight: 700;
      align-self: auto;
      font-size: 24px;
      cursor: pointer;
      color: #877aa4;
      text-decoration: underline;
      margin-bottom: 2rem;

      &:hover {
        color: #d66b6b;
      }
    }
  }

  /* == Small desktop ===  */
  @media (min-width: 768px) {
  }

  /* == Large desktop ===  */
  @media (min-width: 1200px) {
    .link {
      font-size: 32px;
    }
  }
`;
