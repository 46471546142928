import styled from "styled-components";

export const SectionTitle = styled.h1`
         /* == Mobile ===  */
         display: grid;
         place-items: center;
         margin: 3rem 0 1rem 0;
         text-transform: uppercase;
         color: #4f4d5d;
         font-weight: 600;

         /* == Small desktop ===  */
         @media (min-width: 768px) {

         }

         /* == Large desktop ===  */
         @media (min-width: 1200px) {
           margin: 5rem 0 1rem 0;
           width: 60vw;
         }
       `;
