import React from "react";
import styled from "styled-components";
import mraz from "../../images/mraz.jpeg";
import pond from "../../images/pond.png";
import summer from "../../images/summer-time.jpg";
import sneg from "../../images/sneg.jpeg";
import proteusBlack from "../../images/proteus-black.jpg";
import proteusTwo from "../../images/proteusTwo.jpg";
import { PhotoGrid } from "../../styles/StyledComponents/PhotoGrid";
import { SectionTitle } from "../../styles/StyledComponents/SectionTitle";
import { Paragraph } from "./../../styles/StyledComponents/Paragraph";
import { Wrapper } from "./../../styles/StyledComponents/Wrapper";
import { Photo } from "./../../styles/StyledComponents/Photo";

const BlackOlm = () => {
  return (
    <Section>
      <SectionTitle>DOM ČRNEGA MOČERILA</SectionTitle>
      <PhotoGrid>
        <Photo>
          <img src={proteusBlack} alt="proteus-black" />
        </Photo>
        <Photo>
          <img src={proteusTwo} alt="proteus-alt" />
        </Photo>
      </PhotoGrid>
      <Paragraph>
        Domačija Zupančič se nahaja v osrčju Bele krajine, pokrajine na
        jugovzhodu Slovenije. Je del območja Natura 2000 in vključuje naravni
        habitat endemita - črne človeške ribice (Proteus Anguinus Parkelj). Gre
        torej za edini kraj na svetu, kjer lahko v naravnem okolju proučujemo to
        edinstveno sorodnico znanega zmajevega mladička iz Postojnske jame.
        Močvirnat življenjski prostor (biotop), kjer lahko še vedno pijete vodo
        iz izvira, je tudi dom bobrovega para, vodomcev, divih rac, vodnih
        kokošk, žab in potočnih rakov.
      </Paragraph>
      <PhotoGrid>
        <Photo>
          <img src={summer} alt="summer" />
        </Photo>
        <Photo>
          <img src={pond} alt="jezero" />
        </Photo>
        <Photo>
          <img src={sneg} alt="snow" />
        </Photo>
        <Photo>
          <img src={mraz} alt="mraz" />
        </Photo>
      </PhotoGrid>
    </Section>
  );
};

export default BlackOlm;

const Section = styled(Wrapper)`
  /* == Mobile ===  */
  /* margin: 5rem 0 0 0; */

  /* == Small desktop ===  */
  @media (min-width: 768px) {
  }

  /* == Large desktop ===  */
  @media (min-width: 1200px) {
    margin-top: 150px;
  }
`;
