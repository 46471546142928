import styled from "styled-components";

export const PhotoGrid = styled.div`
  /* == Mobile ===  */
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.25rem;
  /* margin: 0 0 2rem 0; */


  /* == Small desktop ===  */
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  /* == Large desktop ===  */
  @media (min-width: 1200px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;
