import React from "react";
import styled from "styled-components";
import mraz from "../../images/mraz.jpeg";
import pond from "../../images/pond.png";
import summer from "../../images/summer-time.jpg";
import sneg from "../../images/sneg.jpeg";
import proteusBlack from "../../images/proteus-black.jpg";
import proteusTwo from "../../images/proteusTwo.jpg";
import { PhotoGrid } from "../../styles/StyledComponents/PhotoGrid";
import { SectionTitle } from "../../styles/StyledComponents/SectionTitle";
import { Paragraph } from "../../styles/StyledComponents/Paragraph";
import { Wrapper } from "../../styles/StyledComponents/Wrapper";
import { Photo } from "../../styles/StyledComponents/Photo";

const BlackOlmEn = () => {
  return (
    <Section>
      <SectionTitle>Home of the black olm</SectionTitle>
      <PhotoGrid>
        <Photo>
          <img src={proteusBlack} alt="proteus-black" />
        </Photo>
        <Photo>
          <img src={proteusTwo} alt="proteus-alt" />
        </Photo>
      </PhotoGrid>
      <Paragraph>
        Homestead Zupančič is located in Bela krajina and is a part of an
        European environmental project Natura 2000. It is a unique habitat of an
        endemic cave animal, the Black Olm (Proteus Anguinus Parkelj), which is
        a close relative to a famous baby dragon, the White Olm from Postojna
        cave. This marshland where you can still drink from the pristine spring,
        is also a home to a beaver couple, kingfishers, wild ducks, moorhens,
        many frogs and river crabs.
      </Paragraph>
      <PhotoGrid>
        <Photo>
          <img src={summer} alt="summer" />
        </Photo>
        <Photo>
          <img src={pond} alt="jezero" />
        </Photo>
        <Photo>
          <img src={sneg} alt="snow" />
        </Photo>
        <Photo>
          <img src={mraz} alt="mraz" />
        </Photo>
      </PhotoGrid>
    </Section>
  );
};

export default BlackOlmEn;

const Section = styled(Wrapper)`
  /* == Mobile ===  */
  /* margin: 5rem 0 0 0; */

  /* == Small desktop ===  */
  @media (min-width: 768px) {
  }

  /* == Large desktop ===  */
  @media (min-width: 1200px) {
    margin-top: 150px;
  }
`;
