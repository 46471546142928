import React from "react";
import styled from "styled-components";
import { Wrapper } from "../../styles/StyledComponents/Wrapper";

const AccomodationPrices = () => (
  <Section>
    <div className="column">
      <h3 className="title">Paket</h3>

      <p>Dvojna postelja</p>
      <p>Zasebna kopalnica</p>
      <p>Lokalni zajtrk</p>
      <p>1x Voden ogled</p>
      <p>1x Večerja s črnim močerilom</p>
    </div>
    <div className="column">
      <h3 className="title">Cene</h3>
      <p>Ena nočitev</p>
      <p>1 oseba 165EUR</p>
      <p>2 osebi 190EUR</p>

      <div className="line-break"></div>
      <p>Dve nočitvi</p>
      <p>1 oseba 260EUR</p>
      <p>2 osebi 300EUR</p>
    </div>
    <div className="column">
      <h3 className="title">Dodatna ponudba</h3>
      <p>Zen Shiatsu masaža</p>
      <p>Kanu</p>

      <h4>Samo z rezervacijo</h4>
      <p>*le za starejše od 12 let</p>
      <p>*brez hišnih ljubljenčkov</p>
    </div>
  </Section>
);

export default AccomodationPrices;

const Section = styled(Wrapper)`
  /* == Mobile ===  */
  display: flex;
  margin: 1rem 0 1rem;
  padding: 1rem;
  border: 1px solid gray;
  border-radius: 4px;

  .column {
    width: 100%;
    height: 100%;
    margin: 1rem 0;

    .title {
      margin: 1rem 0;
    }

    .line-break {
      height: 1rem;
    }

    h4,
    h3,
    p {
      display: flex;
      text-transform: uppercase;
      justify-content: center;
      align-items: center;
    }

    h3 {
    }

    h4 {
      padding: 0.75rem 0;
    }

    .text-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  /* == Small desktop ===  */
  @media (min-width: 768px) {
  }

  /* == Large desktop ===  */
  @media (min-width: 1200px) {
    /* width: 60vw; */
    margin: 2rem 0 2rem;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    .column {
      margin: 0;
      .title {
        margin: 0 0 1rem 0;
      }
    }
  }

  @media (min-width: 2000px) {
    width: 70%
  }
`;
