import React from "react";
import styled from "styled-components";
import { Wrapper } from "../../styles/StyledComponents/Wrapper";

const AccomodationPricesEn = () => (
  <Section>
    <div className="column">
      <h3 className="title">Package</h3>

      <p>Double Bed</p>
      <p>Private bathroom</p>
      <p>Locally sourced breakfast</p>
      <p>1x Guided tour</p>
      <p>1x Dinner with Black Olm</p>
    </div>
    <div className="column">
      <h3 className="title">Prices</h3>
      <p>One Night</p>
      <p>1 person 165EUR</p>
      <p>2 people 190EUR</p>

      <div className="line-break"></div>
      <p>Two nights</p>
      <p>1 person 260EUR</p>
      <p>2 people 300EUR</p>
    </div>
    <div className="column">
    <h3 className="title">Additional offer</h3>
      <p>Zen Shiatsu massage</p>
      <p>Canoe</p>

      <h4>Reservations only</h4>
      <p>*for older than 12 years old</p>
      <p>*no pets</p>
    </div>
  </Section>
);

export default AccomodationPricesEn;

const Section = styled(Wrapper)`
  /* == Mobile ===  */
  display: flex;
  margin: 1rem 0 1rem;
  padding: 1rem;
  border: 1px solid gray;
  border-radius: 4px;

  .column {
    width: 100%;
    height: 100%;
    margin: 1rem 0;

    .title {
      margin: 1rem 0;
    }

    .line-break {
      height: 1rem;
    }

    h4,
    h3,
    p {
      display: flex;
      text-transform: uppercase;
      justify-content: center;
      align-items: center;
    }

    h3 {
    }

    h4 {
      padding: 0.75rem 0;
    }

    .text-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }

  /* == Small desktop ===  */
  @media (min-width: 768px) {
  }

  /* == Large desktop ===  */
  @media (min-width: 1200px) {
    /* width: 60vw; */
    margin: 2rem 0 2rem;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;

    .column {
      margin: 0;
      .title {
        margin: 0 0 1rem 0;
      }
    }
  }

  @media (min-width: 2000px) {
    width: 70%
  }
`;
