import React from "react";
import styled from "styled-components";
import { Paragraph } from '../../styles/StyledComponents/Paragraph';
import { PhotoGrid } from '../../styles/StyledComponents/PhotoGrid';
import { SectionLogo } from '../../styles/StyledComponents/SectionLogo';
import { SectionTitle } from '../../styles/StyledComponents/SectionTitle';
import { Wrapper } from '../../styles/StyledComponents/Wrapper';
import damOne from '../../images/dam-one.jpeg';
import damTwo from '../../images/dam-two.jpeg';
import zupancicLogo from '../../images/zupancic-logo.png';
import { Photo } from '../../styles/StyledComponents/Photo';

const History = () => (
  <Section>
    {/* <SectionLogo>
      <img src={zupancicLogo} alt="zupancic" />
    </SectionLogo> */}
    <SectionTitle>Družina</SectionTitle>
    <Paragraph>
      Riko in Ana sta kupila posest v začetku 80-ih let. Očistila sta izvir
      Jelševniščice in korak za korakom ob njem in nad razvijajočim potokom
      zgradila lebdečo posest. Ljubezen in spoštovanje do narave, dobre hrane in
      dobre glasbe sta prenašala na otroka. In zgodba se nadaljuje…
    </Paragraph>
    <PhotoGrid>
      <Photo>
        <img src={damOne} alt="jez" />
      </Photo>
      <Photo>
        <img src={damTwo} alt="jez-skupina" />
      </Photo>
    </PhotoGrid>
  </Section>
);

export default History;

const Section = styled(Wrapper)`
  /* == Mobile ===  */


  /* == Small desktop ===  */
  @media (min-width: 768px) {

  }

  /* == Large desktop ===  */
  @media (min-width: 1200px) {

  }
`;
