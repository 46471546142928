import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Header from "./../Header/Header";
import BlackOlm from "./../../components/BlackOlm/BlackOlm";
import BlackOlmEn from "./../../components/BlackOlm/BlackOlmEn";
import History from "./../../components/History/History";
import HistoryEn from "./../../components/History/HistoryEn";
import Food from "./../../components/Food/Food";
import FoodEn from "./../../components/Food/FoodEn";
import Exhibition from "./../../components/Exhibition/Exhibition";
import ExhibitionEn from "./../../components/Exhibition/ExhibitionEn";
import Accomodation from "./../../components/Accomodation/Accomodation";
import AccomodationEn from "./../../components/Accomodation/AccomodationEn";

const Page = () => {
  const [lang, setLang] = useState("si");

  useEffect(() => {
    const savedLang = localStorage.getItem("lang");
    if (savedLang) setLang(savedLang);
  }, [lang]);

  return lang === "si" ? (
    <Wrapper>
      <Header setLang={setLang} />
      <BlackOlm />
      <History />
      <Food lang={lang}/>
      <Exhibition />
      <Accomodation />
    </Wrapper>
  ) : (
    <Wrapper>
      <Header setLang={setLang} />
      <BlackOlmEn />
      <HistoryEn />
      <FoodEn lang={lang}/>
      <ExhibitionEn />
      <AccomodationEn />
    </Wrapper>
  );
};

export default Page;

const Wrapper = styled.div``;
