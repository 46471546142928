import React from "react";
import styled from "styled-components";
import { Paragraph } from "../../styles/StyledComponents/Paragraph";
import { PhotoGrid } from "../../styles/StyledComponents/PhotoGrid";
import { SectionLogo } from "../../styles/StyledComponents/SectionLogo";
import { SectionTitle } from "../../styles/StyledComponents/SectionTitle";
import { Wrapper } from "../../styles/StyledComponents/Wrapper";
import alienLogo from "../../images/alien-logo.png";
import { Photo } from "../../styles/StyledComponents/Photo";
import accomo1 from "../../images/accomo1.jpg";
import accomo2 from "../../images/accomo2.jpg";
import accomo3 from "../../images/accomo3.jpg";
import accomo4 from "../../images/accomo4.jpg";
import AccomodationPrices from './../AccomodationPrices/AccomodationPrices';

const Accomodation = () => (
  <Section>
    <SectionTitle>NOČNO DOŽIVETJE S ČRNO ČLOVEŠKO RIBICO</SectionTitle>
    <Paragraph>
      Za popolno doživetje in potop v edinstven košček narave nudimo nastanitev
      v naši mostiščarski hiši, kjer zaspite ob orkestru žab in žuborenju
      potoka. Med zasebno večerjo boste lahko opazovali prenos črnega močerila v
      živo in se prepustili meditativnemu stanju narave. V sproscenem jutru vam
      postrezemo z lokalnim zajtrkom ob izviru Jelševniščice.
    </Paragraph>
    <PhotoGrid>
      <Photo>
        <img src={accomo2} alt="sobe2" />
      </Photo>
      <Photo>
        <img src={accomo3} alt="sobe3" />
      </Photo>
      <Photo>
        <img src={accomo4} alt="sobe4" />
      </Photo>
      <Photo>
        <img src={accomo1} alt="sobe1" />
      </Photo>
    </PhotoGrid>
    <AccomodationPrices />
    <SectionLogo>
      <img src={alienLogo} alt="alien" />
    </SectionLogo>
  </Section>
);

export default Accomodation;

const Section = styled(Wrapper)`
  /* == Mobile ===  */

  ${SectionLogo} {
    margin: 2rem 0 0 0;
    width: 150px;
    height: auto;
  }

  /* == Small desktop ===  */
  @media (min-width: 768px) {
  }

  /* == Large desktop ===  */
  @media (min-width: 1200px) {
  }
`;
