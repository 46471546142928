import React from "react";
import styled from "styled-components";
import { Paragraph } from '../../styles/StyledComponents/Paragraph';
import { PhotoGrid } from '../../styles/StyledComponents/PhotoGrid';
import { SectionLogo } from '../../styles/StyledComponents/SectionLogo';
import { SectionTitle } from '../../styles/StyledComponents/SectionTitle';
import { Wrapper } from '../../styles/StyledComponents/Wrapper';
import damOne from '../../images/dam-one.jpeg';
import damTwo from '../../images/dam-two.jpeg';
import zupancicLogo from '../../images/zupancic-logo.png';
import { Photo } from '../../styles/StyledComponents/Photo';

const HistoryEn = () => (
  <Section>
    {/* <SectionLogo>
      <img src={zupancicLogo} alt="zupancic" />
    </SectionLogo> */}
    <SectionTitle>Family</SectionTitle>
    <Paragraph>
      Riko and Ana bought this piece of land in the beginning of the 80’s. They
      cleaned up the spring and built a small dam to accumulate water. Slowly, a
      floating farm came to life. They passed on to their kids, love of nature,
      good food and good music. The story goes on…
    </Paragraph>
    <PhotoGrid>
      <Photo>
        <img src={damOne} alt="jez" />
      </Photo>
      <Photo>
        <img src={damTwo} alt="jez-skupina" />
      </Photo>
    </PhotoGrid>
  </Section>
);

export default HistoryEn;

const Section = styled(Wrapper)`
  /* == Mobile ===  */


  /* == Small desktop ===  */
  @media (min-width: 768px) {

  }

  /* == Large desktop ===  */
  @media (min-width: 1200px) {

  }
`;
