import styled from "styled-components";

export const Paragraph = styled.p`
  /* == Mobile ===  */
  display: grid;
  place-items: center;
  text-align: justify;
  margin: 1rem 0;
  font-size: 14px;

  /* == Small desktop ===  */
  @media (min-width: 768px) {
    margin: 1rem 5vw;
    font-size: 0.75rem;
  }

  /* == Large desktop ===  */
  @media (min-width: 1200px) {
    width: 60%;
    margin: 1rem 10vw;
    font-size: 16px;
  }
   @media (min-width: 2000px) {
    width: 50%;

  }
`;
