import React from "react";
import styled from "styled-components";
import { Paragraph } from "../../styles/StyledComponents/Paragraph";
import { PhotoGrid } from "../../styles/StyledComponents/PhotoGrid";
import { SectionLogo } from "../../styles/StyledComponents/SectionLogo";
import { SectionTitle } from "../../styles/StyledComponents/SectionTitle";
import { Wrapper } from "../../styles/StyledComponents/Wrapper";
import alienLogo from "../../images/alien-logo.png";
import { Photo } from "../../styles/StyledComponents/Photo";
import accomo1 from "../../images/accomo1.jpg";
import accomo2 from "../../images/accomo2.jpg";
import accomo3 from "../../images/accomo3.jpg";
import accomo4 from "../../images/accomo4.jpg";
import AccomodationPricesEn from '../AccomodationPrices/AccomodationPricesEn';

const AccomodationEn = () => (
  <Section>
    <SectionTitle>Black olm immersion</SectionTitle>
    <Paragraph>
      If you want to immerse yourself completely into this magical ecosystem,
      you can spend a night in our suspended house and fall asleep with an
      orchestra of frogs and the sound of running water beneath you. During a
      private dinner you will be able to observe the black olm via live stream,
      allowing you to step into a meditative state of surrounding nature.For an
      easy going morning, you will be served a locally sourced breakfast that
      you can enjoy next to the spring.
    </Paragraph>
    <PhotoGrid>
      <Photo>
        <img src={accomo2} alt="sobe2" />
      </Photo>
      <Photo>
        <img src={accomo3} alt="sobe3" />
      </Photo>
      <Photo>
        <img src={accomo4} alt="sobe4" />
      </Photo>
      <Photo>
        <img src={accomo1} alt="sobe1" />
      </Photo>
    </PhotoGrid>
    <AccomodationPricesEn />
    <SectionLogo>
      <img src={alienLogo} alt="alien" />
    </SectionLogo>
  </Section>
);

export default AccomodationEn;

const Section = styled(Wrapper)`
  /* == Mobile ===  */

  ${SectionLogo} {
    margin: 2rem 0 0 0;
    width: 150px;
    height: auto;
  }

  /* == Small desktop ===  */
  @media (min-width: 768px) {
  }

  /* == Large desktop ===  */
  @media (min-width: 1200px) {
  }
`;
