import React from "react";
import styled from "styled-components";
import { Paragraph } from "../../styles/StyledComponents/Paragraph";
import { PhotoGrid } from "../../styles/StyledComponents/PhotoGrid";
import { SectionLogo } from "../../styles/StyledComponents/SectionLogo";
import { SectionTitle } from "../../styles/StyledComponents/SectionTitle";
import { Wrapper } from "../../styles/StyledComponents/Wrapper";
import foodFish from "../../images/food-fish.jpg";
import grannieDog from "../../images/grannie-dog.jpg";
import lamutLogo from "../../images/lamut-logo.png";
import { Photo } from "../../styles/StyledComponents/Photo";
import lamut from "../../images/lamut.jpg";
import sheep from "../../images/sheep.jpg";
// import menuPoletje from
import { Link } from "gatsby";

const FoodEn = () => (
  <Section>
    <SectionTitle>Food</SectionTitle>
    <Paragraph>
      We serve homemade food that grows on our eco certified farm. Jelševniščica
      spring supplies oxygenated water to trout while our apple orchards serve
      as an abundant edible canopy for our sheep. For real gourmands we also
      keep a few huchos.
    </Paragraph>
    <div className="link-container">
      <Link to="/menu" className="link">
        Click to see our menu's
      </Link>
    </div>
    <PhotoGrid>
      <Photo>
        <img src={foodFish} alt="fish" />
      </Photo>
      <Photo>
        <img src={grannieDog} alt="dog" />
      </Photo>
    </PhotoGrid>

    <SectionLogo>
      <img src={lamutLogo} alt="lamut" />
    </SectionLogo>
    <Paragraph>
      Lamut is our brand of eco products. In the heart of Bela krajina meadows,
      we are reviving heirloom apple orchards, especially with the varieties
      native to Slovenian soil. Our apple orchards contribute to the diversity
      of its vibrant ecosystem, which guarantees a long tradition of sustainable
      organic apple production.
    </Paragraph>
    <PhotoGrid>
      <Photo>
        <img src={lamut} alt="lamut" />
      </Photo>
      <Photo>
        <img src={sheep} alt="sheep" />
      </Photo>
    </PhotoGrid>
  </Section>
);

export default FoodEn;

const Section = styled(Wrapper)`
  /* == Mobile ===  */

  ${SectionLogo} {
    margin: 2rem 0 0 0;
    width: 150px;
    height: auto;
  }

  .link-container {
    margin: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .link {
      text-transform: uppercase;
      font-weight: 700;
      align-self: auto;
      font-size: 24px;
      cursor: pointer;
      color: #877aa4;
      text-decoration: underline;
      margin-bottom: 2rem;

      &:hover {
        color: #d66b6b;
      }
    }
  }

  /* == Small desktop ===  */
  @media (min-width: 768px) {
  }

  /* == Large desktop ===  */
  @media (min-width: 1200px) {
    .link {
      font-size: 32px;
    }
  }
`;
