import styled from "styled-components";

export const SectionLogo = styled.div`
  /* == Mobile ===  */
  height: 100px;
  width: auto;
  max-width: 95vw;

  img {
    width: 100%;
    height: 100%;
    vertical-align: middle;
    border-radius: 0.25rem;
    object-fit: cover;
  }

  /* == Small desktop ===  */
  @media (min-width: 768px) {
    /* height: 60vh; */
  }

  /* == Large desktop ===  */
  @media (min-width: 1200px) {
  }
`;
